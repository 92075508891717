#sumsub-websdk-container.iframe > .sumsub-logo {
    opacity: 0 !important;
}

.sumsub-logo {
    opacity: 0 !important;
}

#sumsub-websdk-container > .sumsub-logo {
    opacity: 0 !important;
}